// Generated by Framer (2ca17d4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["lRQS3HXux", "Zq4qSNbFa"];

const serializationHash = "framer-gPtyJ"

const variantClassNames = {lRQS3HXux: "framer-v-xiyrkk", Zq4qSNbFa: "framer-v-of4hw9"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Active: "Zq4qSNbFa", Default: "lRQS3HXux"}

const getProps = ({blueIcon, height, id, link, whiteIcon, width, ...props}) => { return {...props, emW_JERUV: whiteIcon ?? props.emW_JERUV ?? {src: "https://framerusercontent.com/images/Y0jtn2PNoNKBNC9tQ3ndQVzSk0.svg"}, hloLVST9r: blueIcon ?? props.hloLVST9r ?? {src: "https://framerusercontent.com/images/HGQKV6hrVB89ZOcWMt5loOcHrdg.svg"}, T8fiZbj7n: link ?? props.T8fiZbj7n, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "lRQS3HXux"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;blueIcon?: {src: string; srcSet?: string; alt?: string};whiteIcon?: {src: string; srcSet?: string; alt?: string};link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, hloLVST9r, emW_JERUV, T8fiZbj7n, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "lRQS3HXux", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><Link href={T8fiZbj7n} motionChild nodeId={"lRQS3HXux"} smoothScroll><motion.a {...restProps} {...gestureHandlers} className={`${cx(scopingClassNames, "framer-xiyrkk", className, classNames)} framer-130zbse`} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"lRQS3HXux"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-9e366b31-75a0-40cb-992a-fe3ad409ef07, rgb(242, 247, 255))", borderBottomLeftRadius: 12, borderBottomRightRadius: 12, borderTopLeftRadius: 12, borderTopRightRadius: 12, boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.25)", ...style}} variants={{Zq4qSNbFa: {backgroundColor: "rgb(80, 72, 228)"}}} {...addPropertyOverrides({Zq4qSNbFa: {"data-framer-name": "Active"}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", loading: getLoadingLazyAtYPosition(((componentViewport?.y || 0) + (12 + ((((componentViewport?.height || 46) - 24) - 22) / 2)))), pixelHeight: 800, pixelWidth: 800, sizes: "22px", ...toResponsiveImage(hloLVST9r), ...{ positionX: "center", positionY: "center" }}} className={"framer-a9e7dp"} data-framer-name={"Icon"} layoutDependency={layoutDependency} layoutId={"imPEq3_nc"} {...addPropertyOverrides({Zq4qSNbFa: {background: {alt: "", fit: "fill", loading: getLoadingLazyAtYPosition(((componentViewport?.y || 0) + (12 + ((((componentViewport?.height || 46) - 24) - 22) / 2)))), pixelHeight: 800, pixelWidth: 800, sizes: "22px", ...toResponsiveImage(emW_JERUV), ...{ positionX: "center", positionY: "center" }}}}, baseVariant, gestureVariant)}/></motion.a></Link></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-gPtyJ.framer-130zbse, .framer-gPtyJ .framer-130zbse { display: block; }", ".framer-gPtyJ.framer-xiyrkk { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 12px; position: relative; text-decoration: none; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-gPtyJ .framer-a9e7dp { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 22px); overflow: visible; position: relative; width: 22px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-gPtyJ.framer-xiyrkk { gap: 0px; } .framer-gPtyJ.framer-xiyrkk > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-gPtyJ.framer-xiyrkk > :first-child { margin-left: 0px; } .framer-gPtyJ.framer-xiyrkk > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 46
 * @framerIntrinsicWidth 46
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"Zq4qSNbFa":{"layout":["auto","auto"]}}}
 * @framerVariables {"hloLVST9r":"blueIcon","emW_JERUV":"whiteIcon","T8fiZbj7n":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerHPqUnMZLn: React.ComponentType<Props> = withCSS(Component, css, "framer-gPtyJ") as typeof Component;
export default FramerHPqUnMZLn;

FramerHPqUnMZLn.displayName = "How it works button 3";

FramerHPqUnMZLn.defaultProps = {height: 46, width: 46};

addPropertyControls(FramerHPqUnMZLn, {variant: {options: ["lRQS3HXux", "Zq4qSNbFa"], optionTitles: ["Default", "Active"], title: "Variant", type: ControlType.Enum}, hloLVST9r: {__defaultAssetReference: "data:framer/asset-reference,HGQKV6hrVB89ZOcWMt5loOcHrdg.svg?originalFilename=link-chain-connect-svgrepo-com+%281%29.svg&preferredSize=auto", title: "Blue Icon", type: ControlType.ResponsiveImage}, emW_JERUV: {__defaultAssetReference: "data:framer/asset-reference,Y0jtn2PNoNKBNC9tQ3ndQVzSk0.svg?originalFilename=link-chain-connect-svgrepo-com.svg&preferredSize=auto", title: "White Icon", type: ControlType.ResponsiveImage}, T8fiZbj7n: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerHPqUnMZLn, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})